import en from './localization/en.json';
import { components } from 'react-select';
import { toast } from 'react-toastify';
// import crypto from 'crypto'
import CryptoJS from 'crypto-js';
import { ChevronDown, ChevronUp, ChevronsDown, ChevronsUp, Menu } from 'react-feather';
import TaskIcon from '@atlaskit/icon-object/glyph/task/16';
import Bug24Icon from '@atlaskit/icon-object/glyph/bug/16';
import NewFeature24Icon from '@atlaskit/icon-object/glyph/new-feature/16';
import Page24Icon from '@atlaskit/icon-object/glyph/page/16';
import Story24Icon from '@atlaskit/icon-object/glyph/story/16';
import Subtask16Icon from '@atlaskit/icon-object/glyph/subtask/16';

const secretKey = 'USwQzD1gjRxmz7CycFtan7MLuvHsJ0Ly';

const getWebText = (text) => {
  return en[text];
};
const profileNames = (text) => {
  const namesArray = text.split(' ');
  return namesArray[0].charAt(0).toUpperCase() + namesArray[1].charAt(0).toUpperCase();
};

const showToast = (type, message, onClose) => {
  const commonOptions = {
    position: 'top-center',
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    onClose: () => {
      onClose != undefined ? onClose() : '';
      window.onkeydown = function (e) {
        if (e.keyCode === 9 || e.keyCode === 13) {
          return true;
        }
      };
    },
  };

  let toastOptions;

  switch (type) {
    case 'success':
      toastOptions = { ...commonOptions };
      toast.success(message, toastOptions);
      break;

    case 'info':
      toastOptions = { ...commonOptions };
      toast.info(message, toastOptions);
      break;

    case 'error':
      toastOptions = {
        ...commonOptions,
        autoClose: true,
      };
      toast.error(message || 'Error: Contact IT Team', toastOptions);
      window.onkeydown = function (e) {
        if (e.keyCode === 9 || e.keyCode === 13) {
          return false;
        }
      };
      break;

    case 'warn':
      toastOptions = { ...commonOptions };
      toast.warn(message, toastOptions);
      window.onkeydown = function (e) {
        if (e.keyCode === 9 || e.keyCode === 13) {
          return false;
        }
      };
      break;

    default:
      break;
  }
};

// const encryptAES = (plaintext) => {
//   return CryptoJS.AES.encrypt(plaintext, secretKey).toString();
// };

const encryptAES = (data) => {
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const ivHex = CryptoJS.enc.Hex.stringify(iv);
  const encryptedHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);

  return `${ivHex}:${encryptedHex}`;
};

 const decryptAES = (encrypted) => {
  try {
    const textParts = encrypted.split(':');
    const iv = CryptoJS.enc.Hex.parse(textParts.shift());
    const encryptedText = CryptoJS.enc.Hex.parse(textParts.join(':'));
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedText },
      CryptoJS.enc.Utf8.parse(secretKey),
      { iv }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error(error);
    return null;
  }
};

// const decryptAES = (encryptedData) => {
//   const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
//   return bytes.toString(CryptoJS.enc.Utf8);
// };

const isNull = (value) => {
  return value === null || value === undefined || value === '';
};

const priorityArray = {
  HIGHEST: <ChevronsUp size={18} color="red" />,
  HIGH: <ChevronUp size={18} color="red" />,
  MEDIUM: <Menu size={18} color="orange" />,
  LOWEST: <ChevronsDown size={18} color="blue" />,
  LOW: <ChevronDown size={18} color="blue" />,
};

const issueTypeObject = {
  BUG: <Bug24Icon size={18} />,
  TASK: <TaskIcon size={18} />,
  STORY: <Story24Icon size={18} />,
  ENHANCEMENT: <NewFeature24Icon size={18} />,
  DOCS: <Page24Icon size={18} />,
  SUB_TASK: <Subtask16Icon />,
};

const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ marginRight: '8px', marginLeft: '8px' }}>{data.icon}</div>
    {label}
  </div>
);
const CustomSingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props} style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ marginRight: '8px' }}>
      {props.data.icon} {children}
    </div>
  </components.SingleValue>
);
const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

const userDetails = JSON.parse(localStorage.getItem('userInformation'));
const ADMIN =1000

export {
  getWebText,
  isNull,
  profileNames,
  showToast,
  encryptAES,
  stripHtmlTags,
  decryptAES,
  secretKey,
  priorityArray,
  CustomSingleValue,
  userDetails,
  issueTypeObject,
  CustomOption,
  ADMIN
};