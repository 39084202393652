const APILIST = {

  // Login Api
  AUTH_LOGIN_API: 'c75719458e7d784933dca0feff0c4469a66ecfa798112440f2dd7d223033e779',
  API_URL_TO_FORGET_PASSWORD: 'f057ea582066e4d8e3c83a105118973082baf3c0087d6c30dba6a7d998fb2646',
  API_URL_FOR_CHECK_PREVIOUS_PASSWORD: 'c3df5cfea5bbbe454525d88cfbd30c891970039f813881e1d019c86e82441eb9',
  // API_URL_TO_CHANGE_PASSWORD: '/general/user/transactions',
  API_URL_TO_CHANGE_PASSWORD: 'dcf86953dc02649c110ab4a6b272d597a89779f0828b1d6fad0a35ef7b95331c',
  API_FOR_VERIFY_USER_PASSWORD:'c3df5cfea5bbbe454525d88cfbd30c891970039f813881e1d019c86e82441eb9',

  //Email Api
  // API_FOR_VERIFY_USER_EMAIL: '/general/user/transactions',
  API_FOR_VERIFY_USER_EMAIL: 'dcf86953dc02649c110ab4a6b272d597a89779f0828b1d6fad0a35ef7b95331c',
  API_URL_FOR_DATA_DUPLICATE_VALIDATION :'d576a1ca993fdff1877ec4385909d18c37e81eebfccf6615b269b447e14639fc',

  //Saving Api

  // API_URL_FOR_SAVING_USER_DETAILS: '/general/user/transactions',
  API_URL_FOR_SAVING_USER_DETAILS: 'dcf86953dc02649c110ab4a6b272d597a89779f0828b1d6fad0a35ef7b95331c',
  API_URL_FOR_CREATE_PROJECT:'88640a37ba83592ab3b589e2c26a5d961ffacd6c3a02b866b6e00763d31669a1',
  API_URL_FOR_PROJECT_TEAM_INVITE :'c2892b72537c77754c7a6d3c1efc5f869ac840ff1d6aed612c22d120537c5587',

  // Removing Api
  API_URL_FOR_REMOVE_PROJECT_TEAM_MEMBER :'c2892b72537c77754c7a6d3c1efc5f869ac840ff1d6aed612c22d120537c5587',
  API_URL_FOR_DELITING_PROJECT : '88640a37ba83592ab3b589e2c26a5d961ffacd6c3a02b866b6e00763d31669a1',
  // API_URL_FOR_DELITING_USER : '/general/user/transactions',
  API_URL_FOR_DELITING_USER : 'dcf86953dc02649c110ab4a6b272d597a89779f0828b1d6fad0a35ef7b95331c',

  //  Issue Api
  API_FOR_CREATE_ISSUE:'c7741a7f77d51db472d70cc9673aed201db20cef0c3327c452ad0ea913e75eac',
  API_URL_FOR_INSERTING_ATTACHMENT: 'aa9381aa37937435a0de1c5c697f8b8e1c485ed37e55361d405171a9225e8031',
  // API_URL_FOR_ISSUSE_TO_ASSIGNEE: '/issue/assignIssue/transactions',
  API_URL_FOR_ISSUSE_TO_ASSIGNEE: '2b5df1315dbb30a65bcbf3b919286c597b1e92e0c7b0d3c2cdddd3da7051cd7e',
  API_URL_FOR_LINKED_ISSUE_IN_ISSUE :'ee2855fa7f19c3e8e5ef763b07b1767edc63f353b8f6c63d6802f8c33765f693',
  API_URL_FOR_WORKFLOW_VIEW:'d28390b32149da9e24bf1287a0641e984693f7fad947f47c202e035aea49362b',
  // API_URL_FOR_DOWNLOADING_ATTACHMENT:'/general/attachment/download/',
  API_URL_FOR_DOWNLOADING_ATTACHMENT:'c195f5781b9cde3a1e02eb107913de269caf6650ac32d08dc4d8f0acbe136f78',


  //View Api
  API_URL_VIEWS_USER: '9399e95df2b528a2662a1292f89080d8d6375969910c25ef6aef13c515255d07',
  API_URL_FOR_INVITED_USERS:'b3a4ef94e200f2fc51328b3e2a708f2a238ec13cf5577118eb2eaf4d3b860d05',
  API_URL_VIEWS_ROLES: '/general/views/ATS_AIM_ROLE_V',
  API_URL_FOR_VIEWS_PROJECTS:'bacf5fccc40a5526e2a8e81d690fa19c469d4cb2f312b3442d379c647836ddb4',
  API_URL_FOR_VIEWS_PROJECTS_TEAMS:'79727a68533a7e5312c638084056f73bf241449659d3725b752d2fefadcd427d',
  API_FOR_INVITE_LOOKUP_VALUES: 'bbfc859d4a7f6000384a1010b6dee197c3ffa76863493b96541440d85d27b626',  // not used
  API_FOR_CREATE_ISSUE_LOOKUP_VALUES: '1292a0a0f6e6f743a994478a1258b7fb45cc645e56eb4b967fdb40c48b9e0403',
  API_URL_FOR_PROJECTS_VIEWS_JOINED_USER:'bacf5fccc40a5526e2a8e81d690fa19c469d4cb2f312b3442d379c647836ddb4',
  API_URL_FOR_VIEWS_ATTACHMENT: '73f49fbdfba4a06e178d4daaae8adb8b01d5fcf3f8377924ac7d684846f65c73', 
  API_URL_FOR_EMAIL_HISTORY_VIEW: '3e90f061e9e3cc63fee8a1675bdff0955d8025930bf4912e0dd5960d71118187',

  // Project Types
  API_URL_FOR_VIEW_PROJECT_TYPES : "37ac53cb26636a4025dfecaf80b3f645ed563def2faf827db13cb656b4f0d921",
  API_URL_FOR_CREATE_PROJECT_TYPES : "c527976fd46b094f9cff31f3e05488031269bc1b51605c6d477089721ae88d75",

  // Notification Api 
  API_URL_FOR_NOTIFICATION_COUNT:'e2d5979a551d8446161955906b0591276dfa60fcea5c20e73427320357b61617',
  
  // Views Issues
  API_URL_FOR_VIEW_ISSUE:'d7b341f6d5fb11df886e19be760c3c6076196ef3e89f761b65ef79775887b07b' ,
  API_URL_FOR_VIEW_LINKED_ISSUE:'6dbc0a541a70276f83e14de3cf9e8f54cb87798fed64e0bf0ea06aebe6c1dd76' ,
  API_URL_FOR_ISSUE_STATUS_VIEW:'fc1eea2c51a4f2846135c7f76e490d5cff51c30a4bf36e7acbb214fc99578ac2',
  API_URL_FOR_ISSUE_ASSIGNMENT_VIEW:'9e854ec0526b296aa58e08b849ea95a30562d0614ee44eb3ed9035f81871e58c',
  API_URL_FOR_ISSUE_WATCHERS_VIEW:'5fe2036df2d0af17a4da68964cea971160d3e8297f70b8efdffe01f3a1619b26',
  API_URL_FOR_LINKED_ISSUES_PARENT:'43095ec159a0e2daa01c97d69f967a3783596970ca04fc46681f67aad76dbf4c',

  // Comment view
  API_URL_FOR_COMMENTS_VIEW:'4b2c9efa14352b9634f05b71f164a06ecb24c79e33fa829f31f3768bcf4fe5dd',
  API_URL_FOR_COMMENTS_REACTION_VIEW:'75462108273d8e91733c53c00a81b73507e3c855e4e690e7ee3a754fdebe1550',
  API_URL_FOR_INSERTING_COMMENT:'19252c9e5b709d56f80529113175f2dcd1b917c1859eedaa66474b332552a8fb',
  API_URL_FOR_COMMENTS_HISTORY_VIEW:'e48673599316b94b3dc0cfcceec2b0ef84d1fe77f81232aaeec45683b2167629',
  API_URL_FOR_ALL_COMMNETS_VIEW:'aa124182b842a3b7a0c75bbed3227ba74cd970721446ede93efe825614509654',

  // Invite Users
  API_URL_INVITE_USERS: 'b4d2dd05e84fff6b57346b09689f2b69db17d03d23b1405bf45d9b03c47660d3',
  
  // Watchers Api
  API_URL_FOR_ADDING_WATCHER: 'f3e3d0b730cc18f3540ca14feb557dab1d3e301aafe2ddd34017175b094e92fb',
  API_URL_FOR_VIEW_ISSUE_WATCHER : '5fe2036df2d0af17a4da68964cea971160d3e8297f70b8efdffe01f3a1619b26',

  // Update Api
  API_URL_FOR_UPDATIN_ROLE:'b6c8438bf3d3c61fce876a02bf346aa9ad0b02d18769606a1f4cd9ce37e58d2f',
  API_URL_FOR_REFRESH_TOKEN:"6bf5de55c29aa7083c49f76d2667dc1e670e836744f384d364c5d5cf0dae95a7",
};
export default APILIST;
