import axios from 'axios';
import configFile from '../config.json';
import download from 'downloadjs';
import { Buffer } from 'buffer';
import { decryptAES, encryptAES } from '../utils/ConstFunction';

class ApiProvider {
  constructor() {
    // var mode = Config.MODE
    // var base_url = Config[mode]
    let apiURL =
      configFile.MODE === 'LOCALHOST'
        ? `${configFile[configFile.MODE + '_URL']}/`
        : `${configFile[configFile.MODE + '_URL']}/api/`;
    this.api = axios.create({
      baseURL: apiURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // }
  }
  async auth(url, payload) {
    return new Promise((resolve, reject) => {
      const encrytedPayload = { body: encryptAES(JSON.stringify(payload)) };
      this.api
        .post(url, encrytedPayload)
        .then((response) => {
          const decryptPayload = JSON.parse(decryptAES(response.data.body));
          resolve(decryptPayload);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  async post(url, payload) {
    return new Promise((resolve, reject) => {
      let userInfo = JSON.parse(localStorage.getItem('userInformation'));
      if (userInfo !== null && userInfo !== undefined && userInfo !== '') {
        var token = userInfo[0].Token;
        if (token !== null && token !== '') {
          this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        }
      }
      const encrytedPayload = { body: encryptAES(JSON.stringify(payload)) };
      this.api
        .post(url, encrytedPayload)
        .then((response) => {   
          const decryptPayload = JSON.parse(decryptAES(response.data.body));  
          resolve(decryptPayload);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  async get(url, payload) {
    return new Promise((resolve, reject) => {
      let userInfo = JSON.parse(localStorage.getItem('userInformation')); 
      var token = userInfo && userInfo[0].Token;
      if (token !== null) {
        this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      const encrytedPayload = { body: encryptAES(JSON.stringify(payload)) };
      const request = {
        body: payload,
      };
      url += `/${encryptAES(JSON.stringify(request))}`;
      this.api.get(url, encrytedPayload).then((response) => {   
        const decryptPayload = JSON.parse(decryptAES(response.data.body));
        resolve(decryptPayload);
      });
    });
  }

  async downloadFile(url, fileName) {
    return new Promise((resolve, reject) => {
      this.api.get(url, { responseType: 'blob' }).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, fileName, content);
        resolve('success');
      });
    });
  }

  async getBufferData(url) {
    try {
      const response = await this.api.get(url, { responseType: 'arraybuffer' });
      const bufferData = Buffer.from(response.data);
      return bufferData;
    } catch (error) {
      throw error;
    }
  }
}
const api = new ApiProvider();
export default api;